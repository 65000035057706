<!--
--------------------------------------------------------------------------------
<copyright file="ManufacturingPOStatus.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('report.shop_floor_console.po_status') }}</summary>
      <p class="text">{{ $t('widget_info_text.po_status') }}</p>
    </details>
    <details>
      <summary>{{ $t('status') }}</summary>
      <p class="text">{{ $t('widget_info_text.manufacturing_dim_po_status') }}</p>
    </details>
    <details>
      <summary>{{ $t('report.shop_floor_console.routing') }}</summary>
      <p class="text">{{ $t('widget_info_text.manufacturing_dim_routing') }}</p>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class ManufacturingPOStatusInfo extends Vue {}
</script>
